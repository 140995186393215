<template>

 <header class="header-2">
    <div class="page-header min-vh-75" style="background-image: url('./assets/img/curved-images/curved.jpg')">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-5 me-lg-auto my-lg-auto text-lg-right mt-5 ">
            <br>

              <a href="https://id.linkedin.com/in/fran-sala-monda-07593689" class="btn btn-dark mb-0 me-2" target="_blank">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" focusable="false" color="rgb(255, 255, 255)" style="user-select: none; width: 30px; height: 100%; display: inline-block; fill: rgb(255, 255, 255); flex-shrink: 0;"><g color="rgb(255, 255, 255)" weight="regular"><rect x="36" y="36" width="184" height="184" rx="8" stroke-width="16" stroke="rgb(255, 255, 255)" stroke-linecap="round" stroke-linejoin="round" fill="none"></rect>
                <line x1="120" y1="112.00094" x2="120" y2="176.00094" fill="none" stroke="rgb(255, 255, 255)" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></line>
                <line x1="88" y1="112.00094" x2="88" y2="176.00094" fill="none" stroke="rgb(255, 255, 255)" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></line><path d="M120,140.00094a28,28,0,1,1,56,0v36" fill="none" stroke="rgb(255, 255, 255)" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></path>
                <circle cx="88" cy="79.99998" r="12"></circle></g>
                </svg>
                </a>

              <a href="https://github.com/fransalamonda" class="btn btn-dark mb-0 me-2" target="_blank">
                        
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" focusable="false" color="rgb(255, 255, 255)" style="user-select: none; width: 30px; height: 100%; display: inline-block; fill: rgb(255, 255, 255); flex-shrink: 0;"><g color="rgb(255, 255, 255)" weight="regular"><path d="M84,240a24,24,0,0,0,24-24V168" fill="none" stroke="rgb(255, 255, 255)" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></path><path d="M172,240a24,24,0,0,1-24-24V168" fill="none" stroke="rgb(255, 255, 255)" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></path>
                          <path d="M152,168h16a24,24,0,0,1,24,24v8a24,24,0,0,0,24,24" fill="none" stroke="rgb(255, 255, 255)" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></path>
                          <path d="M104,168H88a24,24,0,0,0-24,24v8a24,24,0,0,1-24,24" fill="none" stroke="rgb(255, 255, 255)" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></path>
                          <path d="M111.825,63.99934A51.9599,51.9599,0,0,0,68,40a51.90058,51.90058,0,0,0-3.48841,44.7036A49.25789,49.25789,0,0,0,56,112v8a48,48,0,0,0,48,48h48a48,48,0,0,0,48-48v-8a49.25769,49.25769,0,0,0-8.5116-27.29639A51.90061,51.90061,0,0,0,188,40a51.95992,51.95992,0,0,0-43.82535,23.99983Z" fill="none" stroke="rgb(255, 255, 255)" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></path></g>
                          </svg>

                          <div data-framer-component-type="Text" style="outline: none; display: flex; flex-direction: column; justify-content: flex-start; flex-shrink: 0; --framer-font-family:&quot;Inter&quot;, sans-serif; --framer-font-style:normal; --framer-font-weight:400; --framer-text-color:rgb(255, 255, 255); --framer-font-size:14px; --framer-letter-spacing:0px; --framer-text-transform:none; --framer-text-decoration:none; --framer-line-height:1.7em; --framer-text-alignment:center; --framer-link-text-color:rgb(255, 255, 255); --framer-link-text-decoration:underline; line-height: 1px; font-size: 0px; opacity: 0; transform: translateX(-50%); transform-origin: 50% 50% 0px;" data-center="x" class="framer-iicr54"><span style="font-size: 0; line-height: 0; tab-size: 4; white-space: inherit; word-wrap: inherit"><span style="direction: ltr; font-size: 0"><span style="">Github</span><br></span></span>
                          </div>
                          <!-- </div> -->
                          </a>            

              <a href="https://twitter.com/Fransalamonda" class="btn btn-dark mb-0 me-2" target="_blank">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" focusable="false" color="rgb(255, 255, 255)" style="user-select: none; width: 30px; height: 50%; display: inline-block; fill: rgb(255, 255, 255); flex-shrink: 0;">
                                      <g color="rgb(255, 255, 255)" weight="regular">
                                        <path d="M128,88.00288a40.00668,40.00668,0,0,1,76.67148-16.00327L240,72l-32.26239,32.2625A128.00746,128.00746,0,0,1,80,224c-32,0-40-12-40-12s32-12,48-36c0,0-64-32-48-120,0,0,40,40,87.9862,48Z" fill="none" stroke="rgb(255, 255, 255)" stroke-linecap="round" stroke-linejoin="round" stroke-width="16">
                                          </path>
                                          </g>
                                          </svg>
                          </a>

             <a href="https://www.instagram.com/fransalamonda/" class="btn btn-dark mb-0 me-2" target="_blank">
            
               <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" focusable="false" color="rgb(255, 255, 255)" style="user-select: none; width: 30px; height: 100%; display: inline-block; fill: rgb(255, 255, 255); flex-shrink: 0;"><g color="rgb(255, 255, 255)" weight="regular"><circle cx="128" cy="128" r="40" fill="none" stroke="rgb(255, 255, 255)" stroke-miterlimit="10" stroke-width="16"></circle><rect x="36" y="36" width="184" height="184" rx="48" stroke-width="16" stroke="rgb(255, 255, 255)" stroke-linecap="round" stroke-linejoin="round" fill="none"></rect><circle cx="180" cy="75.99998" r="12"></circle></g>
             </svg>
            </a>

                <a href="mailto:fransalamonda@gmail.com" class="btn btn-dark mb-0 me-2" target="_blank">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" focusable="false" color="rgb(255, 255, 255)" style="user-select: none; width: 30px; height: 100%; display: inline-block; fill: rgb(255, 255, 255); flex-shrink: 0;"><g color="rgb(255, 255, 255)" weight="regular"><polyline points="224 56 128 144 32 56" fill="none" stroke="rgb(255, 255, 255)" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></polyline><path d="M32,56H224a0,0,0,0,1,0,0V192a8,8,0,0,1-8,8H40a8,8,0,0,1-8-8V56A0,0,0,0,1,32,56Z" fill="none" stroke="rgb(255, 255, 255)" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></path><line x1="110.54541" y1="128.00013" x2="34.4668" y2="197.73926" fill="none" stroke="rgb(255, 255, 255)" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></line><line x1="221.53418" y1="197.73926" x2="145.45424" y2="127.99964" fill="none" stroke="rgb(255, 255, 255)" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></line></g></svg>
                </a>
            
          </div>
          
          <div class="col-lg-6 d-flex justify-content-center flex-column">
                    
                    <div class="position-relative border-radius-xl overflow-hidden shadow-lg mb-2">
                      <br>
                      <div>
                        <img class="w-100" src="../../public/assets/img/chat_v1.png" alt="features-6">
                        </div>
                    </div>
            </div>

        </div>

      </div>

      <!-- <div class="position-absolute w-100 z-index-1 bottom-0">
        <svg class="waves" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 40" preserveAspectRatio="none" shape-rendering="auto">
          <defs>
            <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
          </defs>
          <g class="moving-waves">
            <use xlink:href="#gentle-wave" x="48" y="-1" fill="rgba(255,255,255,0.40" />
            <use xlink:href="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.35)" />
            <use xlink:href="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.25)" />
            <use xlink:href="#gentle-wave" x="48" y="8" fill="rgba(255,255,255,0.20)" />
            <use xlink:href="#gentle-wave" x="48" y="13" fill="rgba(255,255,255,0.15)" />
            <use xlink:href="#gentle-wave" x="48" y="16" fill="rgba(255,255,255,0.95" />
          </g>
        </svg>
      </div> -->


    </div>
  </header>
  <!-- <section class="pt-3 pb-4" id="count-stats">
    <div class="container">
      <div class="row">
        <div class="col-lg-9 z-index-2 border-radius-xl mt-n10 mx-auto py-3 blur shadow-blur">
          <div class="row">
            <div class="col-md-4 position-relative">
              <div class="p-3 text-center">
                <h1 class="text-gradient text-primary"><span id="state1" countTo="70">0</span>+</h1>
                <h5 class="mt-3">Coded Elements</h5>
                <p class="text-sm">From buttons, to inputs, navbars, alerts or cards, you are covered</p>
              </div>
              <hr class="vertical dark">
            </div>
            <div class="col-md-4 position-relative">
              <div class="p-3 text-center">
                <h1 class="text-gradient text-primary"> <span id="state2" countTo="15">0</span>+</h1>
                <h5 class="mt-3">Design Blocks</h5>
                <p class="text-sm">Mix the sections, change the colors and unleash your creativity</p>
              </div>
              <hr class="vertical dark">
            </div>
            <div class="col-md-4">
              <div class="p-3 text-center">
                <h1 class="text-gradient text-primary" id="state3" countTo="4">0</h1>
                <h5 class="mt-3">Pages</h5>
                <p class="text-sm">Save 3-4 weeks of work when you use our pre-made pages for your website</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section> -->
  <div class="home"></div>

<section class="py-5" style="background-color: #000000">
    <!-- <div class="container">
      <div class="row">
        <div class="row text-center my-sm-5 mt-5">
          <div class="col-lg-6 mx-auto">
            <h2 class="text-primary text-gradient mb-0">Boost creativity</h2>
            <h2 class="">With our coded pages</h2>
            <p class="lead">The easiest way to get started is to use one of our <br> pre-built example pages. </p>
          </div>
        </div>
      </div>
    </div> -->
    <div class="container mt-5">
      <div class="row">
        <div class="col-md-12">
          <div class="row mt-4">

            <!-- <div class="col-md-6">
              <a href="./pages/about-us.html">
                <div class="card move-on-hover">
                  <img class="w-100" src="https://raw.githubusercontent.com/creativetimofficial/public-assets/master/soft-ui-design-system/presentation/section-pages/about-us.jpg" alt="">
                </div>
                <div class="mt-2 ms-2">
                  <h6 class="mb-0">About Us Page</h6>
                </div>
              </a>
            </div>
            <div class="col-md-6 mt-md-0 mt-5">
              <a href="./pages/contact-us.html">
                <div class="card move-on-hover">
                  <img class="w-100" src="https://raw.githubusercontent.com/creativetimofficial/public-assets/master/soft-ui-design-system/presentation/section-pages/contact-us.jpg" alt="">
                </div>
                <div class="mt-2 ms-2">
                  <h6 class="mb-0">Contact Us Page</h6>
                </div>
              </a>
            </div>
            <div class="col-md-6 mt-md-0 mt-6">
              <a href="./pages/sign-in.html">
                <div class="card move-on-hover">
                  <img class="w-100" src="https://raw.githubusercontent.com/creativetimofficial/public-assets/master/soft-ui-design-system/presentation/section-account/sign-in.jpg" alt="">
                </div>
                <div class="mt-2 ms-2">
                  <h6 class="mb-0">Sign In Page</h6>
                </div>
              </a>
            </div> -->

            <div class="col-md-6 mt-md-0 mt-6">
              <div class="page-header section-height-50 my-sm-3 mb-3 border-radius-xl" style="background-image: url('../../public/assets/img/caliak_lap.jpg');">
              <span class="mask bg-gradient-dark"></span>
                  <div class="container">
                    <div class="row">
                      <div class="col-lg-6 ms-lg-5">
                        <h1 class="text-white">Caliak.com</h1>
                        <p class="mt-4 text-white">Full web developer and mobile project developed by 3 people.</p>
                        <!-- <a href="https://www.creative-tim.com/learning-lab/bootstrap/overview/soft-ui-design-system" class="text-white icon-move-right">
                          Read docs
                          <i class="fas fa-arrow-right text-sm ms-1" aria-hidden="true"></i>
                        </a> -->
                      </div>
                    </div>
                  </div>
                </div>
            </div>

          </div>
        </div>
        <!-- <div class="col-md-3 mx-auto mt-md-0 mt-5">
          <div class="position-sticky" style="top:100px !important">
            <h4 class="">Presentation Pages for Company, Sign In Page, Author and Contact</h4>
            <h6 class="text-secondary">These is just a small selection of the multiple possibitilies you have. Focus on the business, not on the design.</h6>
          </div>
        </div> -->
      </div>
  </div>
  </section>



  <!-- <section class="my-0 py-5" style="background-color: #000000">
    
    <div class="container mt-sm-5 mt-3">
      <div class="row">
        <div class="col-lg-3">
          <div class="position-sticky pb-lg-5 pb-3 mt-lg-0 mt-5 ps-2" style="top: 100px">
            <h3>Caliak.com</h3>
            <h6 class="text-secondary font-weight-normal pe-3">Full web developer and mobile project developed by 3 people.</h6>
          </div>
        </div>
        <div class="col-lg-9">
          
          
          <div class="row mt-3">
            <div class="col-12">
              <div class="position-relative border-radius-xl overflow-hidden shadow-lg mb-7">
   
                <div class="tab-content tab-space">
                  <div class="tab-pane active" id="preview-avatar">
                    <div class="col-lg-5 col-md-8 order-2 order-md-2 order-lg-1">
                              <div class="position-relative ms-lg-5 mb-0 mb-md-7 mb-lg-0 d-none d-md-block d-lg-block d-xl-block h-75">
                                <img  width="850" height="400" src="../../public/assets/img/caliak.png" class="border-radius-xl position-relative " alt="">
                              
                                    
                              </div>
                        </div>
                  </div>
                  <div class="tab-pane" id="code-avatar">
                    <div class="position-relative p-4 pb-2">
                      <a class="btn btn-sm bg-gradient-dark position-absolute end-4 mt-3" onclick="copyCode(this);" type="button"><i class="fas fa-copy text-sm me-1"></i> Copy</a>
                      <figure class="highlight"><pre><code class="language-html" data-lang="html">  <span class="nt">&lt;div</span> <span class="na">class=</span><span class="s">"row text-center py-3 mt-3"</span><span class="nt">&gt;</span>
                                <span class="nt">&lt;div</span> <span class="na">class=</span><span class="s">"col-12"</span><span class="nt">&gt;</span>
                                  <span class="nt">&lt;div</span> <span class="na">class=</span><span class="s">"avatar-group"</span><span class="nt">&gt;</span>
                                    <span class="nt">&lt;a</span> <span class="na">href=</span><span class="s">"javascript:;"</span> <span class="na">class=</span><span class="s">"avatar avatar-lg rounded-circle"</span> <span class="na">data-bs-toggle=</span><span class="s">"tooltip"</span> <span class="na">data-bs-placement=</span><span class="s">"bottom"</span> <span class="na">title=</span><span class="s">"Ryan Tompson"</span><span class="nt">&gt;</span>
                                      <span class="nt">&lt;img</span> <span class="na">alt=</span><span class="s">"Image placeholder"</span> <span class="na">src=</span><span class="s">"./assets/img/team-1.jpg"</span><span class="nt">&gt;</span>
                                    <span class="nt">&lt;/a&gt;</span>
                                    <span class="nt">&lt;a</span> <span class="na">href=</span><span class="s">"javascript:;"</span> <span class="na">class=</span><span class="s">"avatar avatar-lg rounded-circle"</span> <span class="na">data-bs-toggle=</span><span class="s">"tooltip"</span> <span class="na">data-bs-placement=</span><span class="s">"bottom"</span> <span class="na">title=</span><span class="s">"Romina Hadid"</span><span class="nt">&gt;</span>
                                      <span class="nt">&lt;img</span> <span class="na">alt=</span><span class="s">"Image placeholder"</span> <span class="na">src=</span><span class="s">"./assets/img/team-2.jpg"</span><span class="nt">&gt;</span>
                                    <span class="nt">&lt;/a&gt;</span>
                                    <span class="nt">&lt;a</span> <span class="na">href=</span><span class="s">"javascript:;"</span> <span class="na">class=</span><span class="s">"avatar avatar-lg rounded-circle"</span> <span class="na">data-bs-toggle=</span><span class="s">"tooltip"</span> <span class="na">data-bs-placement=</span><span class="s">"bottom"</span> <span class="na">title=</span><span class="s">"Alexander Smith"</span><span class="nt">&gt;</span>
                                      <span class="nt">&lt;img</span> <span class="na">alt=</span><span class="s">"Image placeholder"</span> <span class="na">src=</span><span class="s">"./assets/img/team-3.jpg"</span><span class="nt">&gt;</span>
                                    <span class="nt">&lt;/a&gt;</span>
                                    <span class="nt">&lt;a</span> <span class="na">href=</span><span class="s">"javascript:;"</span> <span class="na">class=</span><span class="s">"avatar avatar-lg rounded-circle"</span> <span class="na">data-bs-toggle=</span><span class="s">"tooltip"</span> <span class="na">data-bs-placement=</span><span class="s">"bottom"</span> <span class="na">title=</span><span class="s">"Jessica Doe"</span><span class="nt">&gt;</span>
                                      <span class="nt">&lt;img</span> <span class="na">alt=</span><span class="s">"Image placeholder"</span> <span class="na">src=</span><span class="s">"./assets/img/team-4.jpg"</span><span class="nt">&gt;</span>
                                    <span class="nt">&lt;/a&gt;</span>
                                  <span class="nt">&lt;/div&gt;</span>
                                <span class="nt">&lt;/div&gt;</span>
                              <span class="nt">&lt;/div&gt;</span>

                              </code></pre>
                      </figure>
                    </div>
                  </div>
                </div>
              </div>
            </div>
           
          </div>
          
        </div>
      </div>
      
      
      
    </div>
  </section> -->

</template>

<script>
// @ is an alias to /src
//import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'HomeView',
  components: {
    //HelloWorld
  }
}
</script>
