<template>
<div class="container position-sticky z-index-sticky top-0">
    <div class="row">
      <div class="col-12">
        
        <nav class="navbar navbar-expand-lg  blur blur-rounded top-0 z-index-fixed shadow position-absolute my-3 py-2 start-0 end-0 mx-4">
                  <div class="container-fluid">
                    <a class="navbar-brand font-weight-bolder ms-sm-3" href="" rel="tooltip" title="Designed and Coded by Creative Tim" data-placement="bottom" >
                  
                      Fransalamonda
                    </a>
                    <button class="navbar-toggler shadow-none ms-2" type="button" data-bs-toggle="collapse" data-bs-target="#navigation" aria-controls="navigation" aria-expanded="false" aria-label="Toggle navigation">
                      <span class="navbar-toggler-icon mt-2">
                        <span class="navbar-toggler-bar bar1"></span>
                        <span class="navbar-toggler-bar bar2"></span>
                        <span class="navbar-toggler-bar bar3"></span>
                      </span>
                    </button>
                    <div class="collapse navbar-collapse pt-3 pb-2 py-lg-0 w-100" id="navigation">
                      <ul class="navbar-nav navbar-nav-hover ms-lg-12 ps-lg-5 w-100">
                        
                      <li class="nav-item ms-lg-auto">
                              <router-link to="/" class="nav-link ps-2 d-flex justify-content-between cursor-pointer align-items-center">Home</router-link>
                         
                        </li>
                          <li class="nav-item dropdown dropdown-hover mx-2">
                          <router-link to="/about" class="nav-link ps-2 d-flex justify-content-between cursor-pointer align-items-center">About</router-link>
                            
                        </li>
                      </ul>
                    </div>
                  </div>
                </nav>

        <!-- End Navbar -->
      </div>
    </div>
  </div>  

  <router-view/>


<!-- -------   END PRE-FOOTER 2 - simple social line w/ title & 3 buttons    -------- -->
  <footer class="footer pt-0 mt-0" style="background-color: #000000" >
    <!-- <hr class="horizontal dark mb-5"> -->
    <div class="container">
      <div class=" row">
        
        <div class="col-12">
          <div class="text-center">
            <p class="my-4 text-sm" style="color: #fbf6fa !important;">Built with code 🤯 in Vue Js © 2022 by <a href="https://www.instagram.com/fransalamonda/?hl=en" target="_blank" style="color: #fbf6fa !important;">@fransalamonda</a>.</p>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<style>
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
} */
</style>
